import React from "react";

const SupportedServers = () => {
    return (
          <div className="account">
            <div className="account__wrapper2">
              <div className="account__card2">
                <p className="account__name2">Supported Servers</p>
                <p className="account__sub1">The following servers are currently using this kill feed implementation:</p>
                <ul className="account__sub1">
                  <li><a href="https://discord.gg/gRRwGCSYj9" target="_blank" rel="noreferrer noopener">[ EU/GER/ENG ] 1. DOG Hundehütte New Players Welcome</a> (player and squad views)</li>
                  <li><a href="https://www.38id.de/discord" target="_blank" rel="noreferrer noopener">[38.] Infanterie Division | 38id.de | mic.only! | german | votemap</a> (player and squad views)</li>
                  <li><a href="https://www.38id.de/discord" target="_blank" rel="noreferrer noopener">[38.] Infanterie Division | 38id.de | Eventserver</a> (whitelisted streamers only)</li>
                </ul>
                <p className="account__sub1">
                  If you are running an at least somewhat popular HLL server and would like to offer a live kill feed to your players too, feel free to contact me (Pinguin) on Discord and ask for addition of your server.
                </p>
              </div>
            </div>
          </div>
    );
};

export default SupportedServers;

