import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
 
export const Nav = styled.nav`
    background: orangered;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem calc((100vw - 1000px) / 2);
    z-index: 12;
`;

export const NavLink = styled(Link)`
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-align: center;
    padding: 0 5px;
    height: 100%;
    cursor: pointer;
    &:hover {
      color: black;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;
`;

