import React from "react";

const RecommendedSetup = () => {
    return (
          <div className="account">
            <div className="account__wrapper2">
              <div className="account__card2">
                <p className="account__name2">Fully transparent setup using the Steam overlay browser</p>
                <p className="account__sub1">To remove the semi-transparent area, you need to inject custom CSS into Steam using a software called SFP. This software needs to be running every time you play HLL for this to work.</p>
                <p className="account__sub1">One-time setup:</p>
                <ul className="account__sub1">
                  <li>Download latest "SFP_UI-win10-x64-SelfContained.zip" from <a href="https://github.com/PhantomGamers/SFP/releases" target="_blank" rel="noreferrer noopener">https://github.com/PhantomGamers/SFP/releases</a></li>
                  <li>Extract the downloaded zip and run the contained exe file.</li>
                  <li>In SFP: "Open file" -&gt; libraryroot.custom.css (use notepad).</li>
                  <li>Paste the following and save the file: ._22LPPg9PMLlPRAP1u7N5mb &#123; background: none; &#125;</li>
                  <li>In SFP: Click on "Restart Steam".</li>
                </ul>
                <p className="account__sub1">Setup:</p>
                <ul className="account__sub1">
                  <li>If SFP is not already running, start it and click on "Restart Steam".</li>
                  <li>While playing on a supported server, enter !killfeed (to see your kills / deaths) or !killfeeds (to see all kills / deaths of your squad) in chat to get your session token.</li>
                  <li>Visit this site in your steam overlay browser and enter your token.</li>
                  <li>Resize the browser to fit your needs and place it in the top right corner.</li>
                  <li>Pin the browser using the pin icon (top right corner) so it remains visible when closing the Steam overlay.</li>
                </ul>
                <p className="account__sub1">Tips:</p>
                <ul className="account__sub1">
                  <li>For streamers using OBS: Enable "Capture third-party overlays (such as steam)" in your source settings to include the kill feed in your stream.</li>
                  <li>Enable the following SFP options to automatically start SFP on boot: "Show tray icon", "Minimize to tray", "Close to tray", "Start minimized", "Inject on app start", "Run Steam on start" and "Run on boot".</li>
                  <li>A Steam update may require you to change libraryroot.custom.css. If transparency stops working, come back here and make sure you have the right data in that file.</li>
                </ul>
              </div>
            </div>
          </div>
    );
};

export default RecommendedSetup;

