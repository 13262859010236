import React, { useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { toast } from 'react-toastify';
import classNames from "classnames";

var WS_BASE_URL = window.location.host

if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
{
    // Local instance for debugging
    WS_BASE_URL = window.location.hostname + ":8001";
}

var WS_REGISTER_URL = window.location.protocol + "//" + WS_BASE_URL + "/ws/register";
var WS_KILLFEED_URL = "";

var TOKEN_TEXT_SIZE_CSS = "display-size-normal";

function LoginSection({ setToken }) {
    function logInUser(token, screensize) {
        switch (screensize) {
            case "medium":
                TOKEN_TEXT_SIZE_CSS = "display-size-medium";
                break;
            case "large":
                TOKEN_TEXT_SIZE_CSS = "display-size-large";
                break;
            default:
                TOKEN_TEXT_SIZE_CSS = "display-size-normal";
        }

        if (!token.trim()) {
            const CustomToast = ({text, text_style}) => (
                <div className={text_style} >{text}</div>
            );

            toast(<CustomToast
                text={"Invalid token specified!"}
                text_style={TOKEN_TEXT_SIZE_CSS} />, { autoClose: 5000 });

            return;
        }

        fetch(WS_REGISTER_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: token})
        })
        .then((response) => response.json())
        .then((data) => {
            if (window.location.protocol === "https:") {
                WS_KILLFEED_URL = "wss://" + WS_BASE_URL + data["url"];
            } else {
                WS_KILLFEED_URL = "ws://" + WS_BASE_URL + data["url"];
            }

            setToken && setToken(token);
        })
        .catch((error) => {
            const CustomToast = ({text, text_style}) => (
                <div className={text_style} >{text}</div>
            );

            toast(<CustomToast
                text={"Invalid token specified!"}
                text_style={TOKEN_TEXT_SIZE_CSS} />, { autoClose: 5000 });
        });
    }

    return (
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__profile">
                <p className="account__name">Hell Let Loose Kill-Feed</p>
                <p className="account__sub1">
                  Enter your token below to view your kill feed.
                  To receive a token, enter !killfeed (to see your kills / deaths) or !killfeeds (to see all kills / deaths of your squad) in chat while playing on a supported server.
                </p>
              </div>
              <input id="token" onKeyDown={(e) => { if (e.key === "Enter") logInUser(e.target.value, document.getElementById("screensize").value); }} className="form-control" />
              <select id="screensize" className="form-control">
                <option value="normal">Normal size (for 1080p displays)</option>
                <option value="medium">Medium size (for 1440p displays)</option>
                <option value="large">Large size (for 2160p displays and up)</option>
              </select>
              <button type="button" onClick={() => logInUser(document.getElementById("token").value, document.getElementById("screensize").value)} className="btn btn-primary account__btn">Go</button>
              <div className="account__profile">
                <br />
                <p className="account__sub2">
                  This kill feed implementation is brought to you by Pinguin.
                  Original idea by The Circle, give them the credits they deserve!
                </p>
              </div>
            </div>
          </div>
        </div>
    );
}

const Home = ({token, setToken}) => {
    const { readyState, lastJsonMessage } = useWebSocket(WS_KILLFEED_URL, {
        share: true,
        filter: () => true,
        retryOnError: false,
        onClose: () => {
            WS_KILLFEED_URL = "";
            setToken('');
        }
    }, Boolean(WS_KILLFEED_URL));

    useEffect(() => {
        if (readyState === ReadyState.OPEN && lastJsonMessage)
        {
            if (Object.hasOwn(lastJsonMessage, 'Kill'))
            {
                const player1_style = classNames("player1-name",
                                                 {
                                                     "player-name-green": lastJsonMessage.Kill.player1_is_client,
                                                     "player-name-blue": (lastJsonMessage.Kill.client_fraction === 0 && lastJsonMessage.Kill.player1_fraction !== 1) ||
                                                                         (lastJsonMessage.Kill.client_fraction !== 0 && !lastJsonMessage.Kill.player1_is_client && lastJsonMessage.Kill.player1_fraction === lastJsonMessage.Kill.client_fraction),
                                                     "player-name-red": (lastJsonMessage.Kill.client_fraction === 0 && lastJsonMessage.Kill.player1_fraction === 1) ||
                                                                        (lastJsonMessage.Kill.client_fraction !== 0 && !lastJsonMessage.Kill.player1_is_client && lastJsonMessage.Kill.player1_fraction !== lastJsonMessage.Kill.client_fraction)
                                                 },
                                                 TOKEN_TEXT_SIZE_CSS);
                const player2_style = classNames("player2-name",
                                                 {
                                                     "player-name-green": lastJsonMessage.Kill.player2_is_client,
                                                     "player-name-blue": (lastJsonMessage.Kill.client_fraction === 0 && lastJsonMessage.Kill.player2_fraction !== 1) ||
                                                                         (lastJsonMessage.Kill.client_fraction !== 0 && !lastJsonMessage.Kill.player2_is_client && lastJsonMessage.Kill.player2_fraction === lastJsonMessage.Kill.client_fraction),
                                                     "player-name-red": (lastJsonMessage.Kill.client_fraction === 0 && lastJsonMessage.Kill.player2_fraction === 1) ||
                                                                        (lastJsonMessage.Kill.client_fraction !== 0 && !lastJsonMessage.Kill.player2_is_client && lastJsonMessage.Kill.player2_fraction !== lastJsonMessage.Kill.client_fraction)
                                                 },
                                                 TOKEN_TEXT_SIZE_CSS);
                const CustomToast = ({player1_name, player1_style, player2_name, player2_style, icon}) => (
                    <div>
                      <div className={player1_style} >{player1_name}</div>
                      <div className="kill-icon">💀</div>
                      <div className={player2_style} >{player2_name}</div>
                    </div>
                );

                toast(<CustomToast
                      player1_name={lastJsonMessage.Kill.player1}
                      player1_style={player1_style}
                      player2_name={lastJsonMessage.Kill.player2}
                      player2_style={player2_style} />);
            }
            else if (Object.hasOwn(lastJsonMessage, 'Status') && lastJsonMessage.Status.message.length > 0)
            {
                const CustomToast = ({text, text_style}) => (
                    <div className={text_style} >{text}</div>
                );

                toast(<CustomToast
                    text={lastJsonMessage.Status.message.join("\n")}
                    text_style={TOKEN_TEXT_SIZE_CSS} />, { autoClose: 30000 });
            }
            else if (Object.hasOwn(lastJsonMessage, 'SystemMessage') && lastJsonMessage.SystemMessage.message.length > 0)
            {
                const CustomToast = ({text, text_style}) => (
                    <div className={text_style} >{text}</div>
                );

                toast(<CustomToast
                    text={lastJsonMessage.SystemMessage.message.join("\n")}
                    text_style={TOKEN_TEXT_SIZE_CSS} />, { autoClose: 5000 });
            }
        }
    }, [lastJsonMessage, readyState]);

    return (
        <>
          { !token && <div className="container-fluid"><LoginSection setToken={setToken} /></div> }
        </>
    );
};

export default Home;

