import React from "react";

const BasicSetup = () => {
    return (
          <div className="account">
            <div className="account__wrapper2">
              <div className="account__card2">
                <p className="account__name2">Semi-transparent setup using the Steam overlay browser</p>
                <p className="account__sub1">
                  Using the steam overlay browser, you can display the kill feed in a semi-transparent area on your screen.
                  To get a fully transparent overlay, check out the recommended setup.
                </p>
                <p className="account__sub1">Setup:</p>
                <ul className="account__sub1">
                  <li>While playing on a supported server, enter !killfeed (to see your kills / deaths) or !killfeeds (to see all kills / deaths of your squad) in chat to get your session token.</li>
                  <li>Visit this site in your steam overlay browser and enter your token.</li>
                  <li>Resize the browser to fit your needs and place it in the top right corner.</li>
                  <li>Pin the browser using the pin icon (top right corner) so it remains visible when closing the Steam overlay.</li>
                  <li>Adjust the opacity using the opacity icon (next to the pin icon) until the background is nearly transparent (around 40%).</li>
                </ul>
                <p className="account__sub1">Tips:</p>
                <ul className="account__sub1">
                  <li>For streamers using OBS: Enable "Capture third-party overlays (such as steam)" in your source settings to include the kill feed in your stream.</li>
                </ul>
              </div>
            </div>
          </div>
    );
};

export default BasicSetup;

