import React from "react";
import { Nav, NavMenu, NavLink } from "./NavbarElements";

const Navbar = () => {
    return (
        <Nav>
          <NavMenu>
            <NavLink to="/">KillFeed</NavLink> |
            <NavLink to="/basic_setup">Basic Setup</NavLink> |
            <NavLink to="/recommended_setup">Recommended Setup</NavLink> |
            <NavLink to="/supported_servers">Servers</NavLink>
          </NavMenu>
        </Nav>
    );
};

export default Navbar;

