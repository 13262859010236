import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Navbar from "./components/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages";
import BasicSetup from "./pages/setup_basic";
import RecommendedSetup from "./pages/setup_recommended";
import SupportedServers from "./pages/supported_servers";

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
    const [token, setToken] = useState('');

    return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            pauseOnFocusLoss={false}
            closeOnClick={false}
            closeButton={false}
            draggable={false}
            pauseOnHover={false}
            theme="dark"
          />
          <BrowserRouter>
            { !token && <Navbar /> }
            <Routes>
              <Route path="/" element={<Home token={token} setToken={setToken} />} />
              <Route path="/basic_setup" element={<BasicSetup />} />
              <Route path="/recommended_setup" element={<RecommendedSetup />} />
              <Route path="/supported_servers" element={<SupportedServers />} />
            </Routes>
          </BrowserRouter>
        </>
    );
}

export default App;

